@import '../../shared/variables';

.menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 193px;
  grid-auto-rows: 60px;
  min-height: 100vh;
  border-right: 1px solid $greyColor;
  background-color: $bgMenuColor;
  align-items: center;
  justify-items: center;

  & a {
    text-decoration: none;
  }
}

.logo {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-img {
    width: 31px;
    height: 45px;
  }
  &-text {
    text-align: center;
    font-family: 'MontserratMedium', 'sans-serif';
    font-size: 16px;
    color: $textColor;
  }
}