@import "src/shared/variables";

.req-list {
  grid-area: rl;
  border: 1px solid $greyColor;
  border-radius: 10px;
  width: 100%;
  max-height: 75vh;
  overflow-y: auto;
  padding: 10px 20px;
  align-self: start;
}