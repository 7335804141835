.history-list {
  width: 100%;
  max-height: 300px;
  min-height: 40px;
  overflow: auto;
  background-color: white;
  padding: 0;
  margin: 0;
  &_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 20px;
    height: 40px;
    &:hover {
      background-color: #cce2fa;
      cursor: pointer;
    }
  }
  &_loading {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}