@import "../../../shared/variables";

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  &+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    font-family: 'MontserratMedium', 'sans-serif';
    font-size: 18px;
    letter-spacing: 0.05em;
    color: $textColor;
    &::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $greyColor;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
  &:checked+label::before {
    border-color: $greenLightColor;
    background-color: $greenLightColor;
  }
  &:not(:disabled):not(:checked)+label:hover:before {
    border-color: $greenLightColor;
  }
}