.history {
    position: absolute;
    top: 2px;
    right: 40px;
    max-width: 25%;
    display: inline-flex;
    flex-direction: row;
    z-index: 1;
    & p {
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
        font-size: 14px;
        margin-right: 5px;
        line-height: 44px;
    }
}

.typed-history-list {
    display: inline-flex;
    flex-direction: row;
    border: 1px solid #cce2fa;
    border-radius: 5px;
    padding: 6px 5px;
    overflow-x: hidden;
    overflow-y: hidden;
}