@import './src/shared/variables';

.list-item {
  padding: 5px 10px;
  font-family: 'MontserratMedium', 'sans-serif';
  font-size: 16px;
  color: $textColor;
  &.Mui-focused {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
  &[aria-selected="true"] {
    background-color: $greenLightColor;
    color: white;
  }
}