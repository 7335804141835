@import "../../../../shared/variables";

.button-with-icon {
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 10px;
  border: 1px solid transparent;
  padding: 0 10px;
  transition: border-color 0.2s ease;
  &:hover {
    cursor: pointer;
    border-color: $greyColor;
  }
  & img {
    width: 21px;
    height: 21px;
    margin-right: 10px;
  }
  & p {
    font-family: 'MontserratMedium', 'sans-serif';
    font-size: 16px;
    color: $textColor;
  }
}