@import './shared/variables.scss';

@font-face {
  font-family: 'OpenSansBold';
  src: url('assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSansRegular';
  src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'OpenSansMedium';
  src: url('assets/fonts/OpenSans-Medium.ttf') format('truetype');
  font-display: swap;
}


@font-face {
  font-family: 'MontserratMedium';
  src: url('assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: hidden;*/
}

/** {
  outline: solid 1px red;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: $greyColor;
  border: 3px solid white;
}

*::-webkit-scrollbar-thumb {
  background-color: $greyColor;
  border-radius: 20px;
  border: 3px solid $greyColor;
}

*::-webkit-scrollbar-button {
  background-color: transparent;
}