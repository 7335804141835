@import "src/shared/variables";

.wrapper {
  display: grid;
  grid-template-columns: 270px auto;
  grid-template-rows: 1fr;
}

.req-wrap {
  padding: 0 55px 45px 55px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 2fr 1.5fr 1.5fr 2fr;
  grid-template-rows: 160px auto;
  column-gap: 60px;
  grid-template-areas:
    "search . . . ."
    "rl rl rl rl rl";
}

hr {
  background-color: $greyColor;
  border: none;
  height: 1px;
}

.req-form-wrap {
  display: grid;
  align-items: center;
  grid-template-columns: 125px 32px 48px 111px 43px 20px 90px 70px 20px;
  grid-gap: 20px;
}