@import "./src/shared/variables";

.control-wrap {
  position: relative;
}

.control-input {
  background: transparent;
  outline: none;
  border: 1px solid $greyColor;
  padding: 5px 10px;
  border-radius: 10px;
  height: 37px;
  width: 100%;
  font-family: 'MontserratMedium', 'sans-serif';
  font-size: 16px;
  color: $textColor;
  transition: border-color 0.2s ease;
  &:focus {
    border-width: 2px;
    border-color: $greenLightColor;
  }
  &.invalid {
    border-width: 2px;
    border-color: orangered;
  }
}

.error-message {
  position: absolute;
  left: 0;
  bottom: -17px;
  font-family: 'MontserratMedium', 'sans-serif';
  font-size: 12px;
  white-space: nowrap;
  color: orangered;
}