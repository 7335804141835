.button-img {
	width: 42px;
	height: 42px;
	margin-left: 15px;
	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}
	&.disabled {
		opacity: 0.3;
		&:hover {
			opacity: 0.3;
			cursor: default;
		}
	}
	&.sm {
      width: 26px;
      height: 26px;
	}
}

[data-tooltip] {
	position: relative; /* Относительное позиционирование */
	&::after {
		content: attr(data-tooltip); /* Выводим текст */
		position: absolute; /* Абсолютное позиционирование */
		white-space: nowrap;
		right: 45px;
		top: 0; /* Положение подсказки */
		background: #05396b; /* Синий цвет фона */
		color: #fff; /* Цвет текста */
		border-radius: 10px;
		padding: 0.5em; /* Поля вокруг текста */
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
		pointer-events: none; /* Подсказка */
		opacity: 0; /* Подсказка невидима */
		transition: 0.3s ease; /* Время появления подсказки */
	}
	&:hover::after {
		opacity: 1; /* Показываем подсказку */
		top: 0; /* Положение подсказки */
	}
}
