@import "./src/shared/variables";

.btn {
  height: 37px;
  font-family: 'MontserratMedium', 'sans-serif';
  font-size: 16px;
  border-radius: 10px;
  color: white;
  border: 1px solid transparent;
  transition: filter 0.2s ease;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  &.primary {
    background-color: $greenColor;
    &.disabled {
      background-color: $greyColor;
      cursor: default;
      filter: none;
    }
  }
  &.abort {
    background-color: $greenDarkColor;
    &.disabled {
      background-color: $greyColor;
    }
  }
}