.default-list-item {
  padding: 5px 5px;
  background-color: white;
  min-height: 30px;
  color: black;
  display: flex;
  align-items: center;
  font-family: 'OpenSansRegular', 'sans-serif';
  &:hover {
    background-color: #cce2fa;
    cursor: pointer;
  }
  &.searched {
    background-color: greenyellow;
    &:hover {
      background-color: #cce2fa;
      cursor: pointer;
    }
  }
}